<template>
  <div>
    <div class="f-20 mb-3"><b>PDPA EMAIL</b></div>
    <div class="title-tabs">PDPA Title</div>

    <b-container class="no-gutters bg-white">
      <b-tabs
        pills
        card
        :vertical="isMobile"
        nav-wrapper-class="tabs-title-spacing"
        v-model="tabIndex"
        @activate-tab="handleTabsChange"
      >
        <b-tab
          v-for="tab of tabs"
          :title="tab.topic"
          :key="tab.id"
          @click="getPDPAbyEmail(tab.id)"
          @change="handleTabs(tab.id)"
          lazy
        >
          <b-card-text>
            <b-skeleton-wrapper :loading="isLoading">
              <EmailEditor
                ref="emailEditor"
                :variableItem="MCEVariable"
                v-model="MCEvalue"
                @onDataChange="(val) => (MCEvalue = val)"
              />
            </b-skeleton-wrapper>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-container>
    <FooterAction routePath="/setting" @submit="exportHtml()" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import ModalLoading from "@/components/modal/ModalLoading";
import EmailEditor from "@/components/inputs/grapesjs/TextEditorPDPA";

export default {
  components: { ModalLoading, EmailEditor },
  data() {
    return {
      tabIndex: 1,
      tabs: [],
      MCEvalue: "",
      design: "",
      freezeMCEValue: "",
      MCEVariable: [],
      id: 1,
      screenSize: window.innerWidth,
      newTabIndex: 1,
      prevTabIndex: 1,
      isLoading: false,
      previewHtml: "",
    };
  },
  mounted() {},
  watch: {
    screenSize(val) {
      return val;
    },
  },
  computed: {
    isMobile() {
      return this.screenSize < 1024 ? false : true;
    },
  },
  created() {
    this.getList();
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    // editorLoaded() {
    //   this.$refs.emailEditor[0].loadDesign(this.design);
    // },
    async exportHtml() {
      await this.$refs.emailEditor[0].getHtml();

      var data = {
        html: this.MCEvalue,
        design: this.design,
      };
      await this.saveForm(data);
    },
    onResize(e) {
      this.screenSize = window.innerWidth;
    },
    async getList() {
      const response = await this.$Axios(
        `${process.env.VUE_APP_API}/setting/pdpaemail`
      );

      this.tabs = response.data.detail.filter((el) => el.topic);
      this.id = response.data.detail[0].id;
      this.getPDPAbyEmail(this.id, true);
    },
    async getPDPAbyEmail(id, condition) {
      this.isLoading = true;
      this.id = id;
      if (this.freezeMCEValue == this.MCEvalue || condition) {
        const response = await this.$Axios(
          `${process.env.VUE_APP_API}/setting/pdpaemail/${id}`
        );

        this.MCEvalue = response.data.detail.email_info.value;
        this.design = response.data.detail.email_info.design;
        this.freezeMCEValue = response.data.detail.email_info.value;
        this.MCEVariable = response.data.detail.variable;
        this.previewHtml = response.data.detail.email_info.value;
        this.isLoading = false;
      }
    },
    handleTabs(id) {},
    addVariable(val) {
      var ele = document.getElementsByClassName("mce-edit-focus");
      ele[0].getElementsByTagName("p")[0].innerText += `{{${val}}}`;
      // this.MCEvalue += `{{${val}}}`;
    },
    async saveForm(value) {
      var payload = {
        id: this.tabs[this.tabIndex].id,
        value: value.html,
        design: JSON.stringify(value.design),
      };
      this.$refs.modalLoading.show();
      return this.$Axios
        .post(`${process.env.VUE_APP_API}/setting/pdpaemail`, payload)
        .then((res) => {
          if (res.data.result) {
            this.successAlert().then(() => this.$router.push("/setting"));
          } else {
            this.errorAlert(res.data.message);
          }
          this.$refs.modalLoading.hide();
          return res;
        });
    },
    async handleTabsChange(newTabIndex, prevTabIndex, bvEvent) {
      if (this.tabIndex != newTabIndex) {
        if (this.freezeMCEValue != this.MCEvalue) {
          bvEvent.preventDefault();

          this.prevTabIndex = prevTabIndex;
          this.newTabIndex = newTabIndex;
          this.$swal2
            .fire({
              text: "เนื่องจากข้อมูลมีการแก้ไขคุณหรือต้องบันทึกข้อมูลหรือไม่ ?",
              title: "ต้องการบันทึกข้อมูลหรือไม่ ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "บันทึก",
              denyButtonText: `ไม่บันทึก`,
              cancelButtonText: `ยกเลิก`,
              showDenyButton: true,
              reverseButtons: true,
              customClass: {
                actions: "my-actions",
                cancelButton: "order-1 right-gap",
                confirmButton: "order-3",
                denyButton: "order-2",
              },
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                const res = await this.saveForm();

                if (res.data.result) {
                  this.tabIndex = newTabIndex;
                  this.getPDPAbyEmail(this.id, true);
                }
              } else if (result.isDenied) {
                this.tabIndex = newTabIndex;
                this.getPDPAbyEmail(this.id, true);
              }
            });
        }
      }
    },
  },
};
</script>
<style>
.f-20 {
  font-size: 20px;
}
.nav-link {
  text-decoration: unset;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--font-color) !important;
  background-color: var(--secondary-color);
}
.tabs-title-spacing {
  /* padding: 0px 15px 0 15px !important; */
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-fixed {
  min-width: 150px;
}
.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: auto;
}
iframe {
  height: 80vh !important;
  min-width: unset !important;
}
@media screen and (max-width: 1024px) {
  .tabs-title-spacing {
    border-right: unset;
  }
}
</style>
